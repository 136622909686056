import { SceneView } from '../SceneView';
import { OrbitControls } from './../fix/OrbitControls.js';
import { BaseSceneTool } from './BaseSceneTool';


export const ORBIT_TOOL = "Orbit";

export class OrbitTool extends BaseSceneTool {

    protected _orbit: OrbitControls;

    override attach(view: SceneView) {

        this._orbit = new OrbitControls(view.mainCamera, view.renderer.domElement);

        this._orbit.addEventListener("change", () => this._sceneView?.requestRender());
        super.attach(view);
    }

    update() {

        const newEnabeld = this._isActive && !this._sceneView?.activeTool;

        if (newEnabeld != this._orbit.enabled) {

            this._orbit.enabled = newEnabeld;
            if (!newEnabeld)
                this._orbit.disable();
        }
    }

    readonly name = ORBIT_TOOL;
}