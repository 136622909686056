import { Light, Material } from "three";
import { nodeManager } from "../NodeManager";
import { INodeHandler } from "../abstraction/INodeHandler";
import { Node } from "../Node";
import { SceneView } from "../../view/SceneView";
import { buildProps } from "../../helpers/EditorPropertiesBuilder";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";
import { withTemplate } from "@eusoft/webapp-jsx";

export class LightHandler implements INodeHandler<Light> {

    canHandle(node: Node<Light>): boolean {
        return node.type.includes("Light");
    }

    icon(node: Node<Light>) {
        return withTemplate(<MaterialIcon style="yellow" name="lightbulb" />);
    }

    properties(node: Node<Light>) {

        return buildProps(node, bld => bld
            .float("intensity", prop => prop
                .value(n => n.value.intensity,
                    (v, n) => {
                        n.value.intensity = v;
                        SceneView.active.requestRender();
                    })
                .set({ step: 0.1 })
            )
        );
             
    } 

    readonly priority = 1;
}


nodeManager.register(new LightHandler());