import { ComponentStyle, IComponent, IComponentOptions, setTypeName } from "@eusoft/webapp-core";
import { Component, registerComponent } from "@eusoft/webapp-core/Component";
import { TemplateMap } from "@eusoft/webapp-core/abstraction/ITemplate";
import { forModel } from "@eusoft/webapp-jsx/Helpers";
import { IPanelOptions, Panel } from "./Panel";
import { Class, JsxTypedComponent } from "@eusoft/webapp-jsx";
import "./PanelHost.scss";

export const PanelHostTemplates: TemplateMap<PanelHost> = {

    "Default": forModel(m => <div className={m.className} visible={m.visible}>
        <Class name="single-child" condition={m.content?.length == 1} />
        <Class name="multi-child" condition={m.content?.length > 1} />
        <ul>
            {m.content.forEach(a => <li on-click={() => m.activePanel = a}>
                <Class name="active" condition={m.activePanel == a}/>
                {a.icon}
            </li>)}
        </ul>
        <div className="title">
            {m.activePanel?.icon}
            {m.activePanel?.title}
        </div>
        <div className="container">

            {m.content.forEach(a => <div visible={a == m.activePanel}>
                <Class name={a.className} />
                {a?.content}
            </div>)}
        </div>
    </div>)
}


export interface IPanelHostOptions extends IComponentOptions {


    content?: JsxTypedComponent<IPanelOptions> | JsxTypedComponent<IPanelOptions>[];
}

export class PanelHost extends Component<IPanelHostOptions> { 

    constructor(options?: IPanelHostOptions) {

        super();

        this.init(PanelHost, {
            template: PanelHostTemplates.Default,
            ...options
        });
    }

    override initProps() {

        this.onChanged("activePanel", (v, o) => {

            if (v)
                v.loadAsync();

        });

        this.onChanged("content", (v, o) => {

            if (v && !Array.isArray(v))
                this.content = [v];

            if (this.content?.length > 0)
                this.activePanel = this.content[0];
        })

    }

    activePanel: Panel;

    content: Panel[];
}

registerComponent(PanelHost, "PanelHost");