import { forModel } from "@eusoft/webapp-jsx";
import { IPanelOptions, Panel } from "../components/Panel";
import { SceneView } from "../view/SceneView";
import { Attach } from "@eusoft/webapp-ui/behavoirs/Attach";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";
import { registerComponent } from "@eusoft/webapp-core";


export interface IScenePanelState {

}

export class ScenePanel extends Panel<IScenePanelState> {
    constructor(options?: IPanelOptions) {

        super();

        this.init(ScenePanel, {
            content: forModel<this>(m => <div className="scene-view">
                <Attach load={el => this.sceneView.attach(el)}/>
            </div>),
            icon: <MaterialIcon name="house" />,
            title: "Scene",
            name: "scene",
            ...options
        });

        this.sceneView = new SceneView();
    }

    override getState(ctx): IScenePanelState {
        return undefined;
    }

    override setState(value: IScenePanelState, ctx) {

    }


    readonly sceneView: SceneView;
}

registerComponent(ScenePanel, "ScenePanel");