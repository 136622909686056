import { Else, If, forModel } from "@eusoft/webapp-jsx";
import { IPanelOptions, Panel } from "../components/Panel";
import { Action } from "@eusoft/webapp-ui/components/Action";
import { actions } from "../services/Actions";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";
import { designerConnector } from "../services/DesignerConnector";
import { userSession } from "../services/UserSession";
import { appSettings } from "../services/AppSettings";
import { registerComponent } from "@eusoft/webapp-core";

export class CloudPanel extends Panel {
    constructor(options?: IPanelOptions) {

        super();

        this.init(CloudPanel, {
            content: forModel(this, m => <>

                <div className="login">
                    <If condition={!m.isConnected}>
                        <Action onExecuteAsync={()=>m.connect()}>Connect</Action>
                        <Else>
                            <span>{"Connesso (" + this.connectedUser + ")"}</span>
                        </Else>
                    </If>
                    <Action onExecuteAsync={() => actions.loadSceneFromUriAsync("scenes/scene-andrea.json")}>Sample scene</Action>
                </div>
            </>),
            icon: <MaterialIcon name="cloud"/>,
            name: "cloud",
            title: "Cloud",
            ...options
        });
    }


    async connect() {

        if (!userSession.user?.accessToken) {

            if (appSettings.isDev) {
                userSession.openAsync({
                    accessToken: "OCAStCUWJh5rBzPR9Oo15yss4ywbsUsHcsxT1p5p26LuGtrD7lzVPamZAC77a83ibKh2Pn0ootTe0FJ0dWf1ZAhhffRjHT9ghgJAGR2s5AZDZD",
                    username: "xxx",
                    userId: "7782456301782093"
                });
            }
            else
                await actions.loginAsync();
        }

        if (userSession.user?.accessToken) {

            await designerConnector.startAsync(userSession.user?.accessToken);

            await designerConnector.registerClientAsync({
                deviceId: userSession.deviceId,
                deviceType: "Browser"
            });

            await designerConnector.requestActiveRoomAsync();

            this.connectedUser = userSession.user.userId;
            this.isConnected = true;
        }
    }

    isConnected: boolean;

    connectedUser: string;
}

registerComponent(CloudPanel, "CloudPanel");