

interface IAppSettings {
    baseUrl: string
    isDev?: boolean;
}

const envSettings: Record<string, IAppSettings> = {
    "development": {
        baseUrl: "https://localhost:7022",
        isDev: true
    },
    "production": {
        baseUrl: "https://roomdesigner.eusoft.net"
    }
}

export const appSettings = envSettings[import.meta.env.MODE];
