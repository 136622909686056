import { ViewNode } from "@eusoft/webapp-ui";
import { AppEvent } from "../helpers/AppEvent";
import { nodeManager } from "./NodeManager";
import { getTypeName, objectHierarchy } from "@eusoft/webapp-core/utils/Object";

export interface INodeChangedArgs<TValue> {
    target: Node<TValue>
    type: "value" | "name" | "children";
}

export class Node<TValue = unknown> {

    protected _parent: Node;

    constructor(parent?: Node) {
        this._parent = parent;
        this.type = [];
    }

    update() {

        if (!this.type || this.type.length == 0) {

            if (this.value && typeof this.value == "object") {
                if (Array.isArray(this.value))
                    this.type = ["Group"];
                else
                    this.type = Array.from(objectHierarchy(this.value)).map(getTypeName);
            }
            else
                this.type = [];
        }

        const handlers = nodeManager.getHandlers(this);

        if (!this.displayName) {
            for (const handler of handlers)
                if (handler.displayName) {
                    this.displayName = handler.displayName(this);
                    break;
                }
        }

        if (!this.icon) {
            for (const handler of handlers)
                if (handler.icon) {
                    this.icon = handler.icon(this);
                    break;
                }
        }

    }

    children(): Promise<Iterable<Node<unknown>>> | Iterable<Node<unknown>> {
        return [];
    }

    appendChild(node: Node) {

    }

    removeChild(node: Node) {

    }

    components?: Node[];

    isLeaf?: boolean;

    id: string;

    type: string[];

    value?: TValue;

    icon?: ViewNode;

    displayName?: string;

    set parent(value: Node) {

        if (this._parent)
            this._parent.removeChild(value);

        this._parent = value;

        if (this._parent)
            this._parent.appendChild(this);
    }

    get parent() {
        return this._parent;
    }

    readonly changed = new AppEvent<INodeChangedArgs<TValue>>();
}

