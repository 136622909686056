import { func } from "three/examples/jsm/nodes/Nodes.js";

export function findDomParent<TParent extends Element>(element: Element, selector: (el: TParent) => boolean) {

    let curNode = element;

    while (curNode) {
        if (selector(curNode as TParent))
            return curNode as TParent;
        curNode = curNode.parentElement;
    }
}

export function getDomOffset(el: HTMLElement, relTo: HTMLElement) {


    const result = {
        x: el.getBoundingClientRect().x - relTo.getBoundingClientRect().x,
        y: el.getBoundingClientRect().y - relTo.getBoundingClientRect().y,
    };

    return result;
}