import { Color, Euler, Group, MathUtils, Mesh, MeshStandardMaterial, Object3D, PlaneGeometry, Vector2, Vector3 } from "three";
import { FurnitureGeometry, Guid, IFurnitureObject, IRoomInfo, ISceneObject } from "../services/Entities";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import { apiClient } from "../services/ApiClient";
import { IProgressHandler } from "../abstraction/IProgressHandler";
import { services } from "../services/Services";
import { getOrBuildNode } from "../nodes/NodeBuilder";
import { INodeProvider } from "../nodes/abstraction/INodeProvider";
import { Node } from "../nodes/Node";
import { designerConnector } from "../services/DesignerConnector";
import { eulerFromDegVector,  quatDiff, rotateAxisPoint, rotationVecDeg, setWorldPostion, toQuaternion, toSimpleQuaternion, toSimpleVector } from "./Utils";
import { SceneView } from "./SceneView";
import { ISceneObjectContainer } from "./abstraction/ISceneObjectContainer";


export class RoomScene implements INodeProvider<RoomScene>, ISceneObjectContainer {

    protected _state: IRoomInfo;
    protected _view: Group;
    protected _loader: GLTFLoader;
    protected _furnitureNode: Node<Object3D[]>;


    constructor() {
        this._loader = new GLTFLoader();
        this._loader.dracoLoader = new DRACOLoader();
    }


    protected async initFromStateAsync(state: IRoomInfo, onProgress: IProgressHandler = null) {

        this._state = state;

        if (!onProgress)
            onProgress = () => false;

        const loader = new GLTFLoader();
        loader.dracoLoader = new DRACOLoader();

        this._view = new Group();

        onProgress(0, 1, `Loading room`);

        for (const objState of this._state.sceneObjects) {

            let obj: Object3D;

            if (objState.type == "GLOBAL_MESH") {

                const dataUrl = "data:application/octect-stream;base64," + objState.mesh;
                const data = await (await fetch(dataUrl)).arrayBuffer();
                const glft = await loader.parseAsync(data, "/");

                const mesh = glft.scene.children[0] as Mesh;

                mesh.material = new MeshStandardMaterial({
                    color: new Color(0xa0a0a0)
                });

                glft.scene.rotateY(Math.PI);
                glft.scene.scale.set(1, 1, -1);
                glft.scene.name = "mesh-capture";

                obj = glft.scene;
            }
            else if (objState.type == "WALL_FACE" || objState.type == "FLOOR" || objState.type == "CEILING") {

                const geo = new PlaneGeometry(objState.dimensions.x, objState.dimensions.y);
                const material = new MeshStandardMaterial();

                obj = new Mesh(geo, material);

                obj.position.set(objState.position.x, objState.position.y, objState.position.z);

              
                if (objState.quaternion)
                    obj.setRotationFromQuaternion(toQuaternion(objState.quaternion));
                else {
                    obj.setRotationFromEuler(new Euler(
                        MathUtils.degToRad(objState.rotation.x),
                        MathUtils.degToRad(objState.rotation.z),
                        MathUtils.degToRad(objState.rotation.y)));
                }

                switch (objState.type) {
                    case "WALL_FACE":
                        obj.name = "wall";
                        material.color = new Color("#fff");
                        break;
                    case "FLOOR":
                        obj.name = "floor";
                        material.color = new Color("#f00");
                        break;
                    case "CEILING":
                        obj.name = "ceil";
                        material.color = new Color("#0f0");
                        break;
                }

    
            }
            
            if (obj) {
                obj.userData.type = objState.type;
                obj.userData.state = objState;
                this._view.add(obj);
            }
        }


        let loaded = 0;

        const loaders = this._state.furniture.map(furnState => (async () => {

            const obj = await this.addFurnitureAsync(furnState);
            loaded++;
            onProgress(loaded, loaders.length, `Loading ${obj?.name} (${loaded} / ${loaders.length})`);

        })());

        await Promise.all(loaders);

        onProgress(0, 0);
    }

    updateLayout(sceneObjects: ISceneObject[]) {

        console.log("New layout");
    }

    async center(p: number) {
        const floor = this._view.children.find(a => a.userData.type == "FLOOR");

        this._view.position.set(0, 0, 0);
        this._view.rotation.set(0, 0, 0);

        let wordPos = floor.getWorldPosition(new Vector3());
        setWorldPostion(this._view, wordPos.negate());
        rotateAxisPoint(this._view, new Vector3(0, 0, 0), floor.up, -floor.rotation.z, false);

        SceneView.active.requestRender();
    }

    findFurnitureViewById(id: Guid) {
        return this._view.children.find(a => a.userData.state?.instanceId == id);
    }

    removeFurnitureById(id: Guid) {

        this._view.remove(this.findFurnitureViewById(id));

        this._furnitureNode?.changed.raise({ target: this._furnitureNode, type: "children" });
    }

    async addFurnitureAsync(furnState: IFurnitureObject) {

        const details = await apiClient.catagalogGetDetailsAsync(furnState.modelId);

        if (details.geometry == FurnitureGeometry.Model) {

            const model = await apiClient.catalogOpenResourceAsync(new URL(details.modelUri).pathname.substring(10));

            const glft = await this._loader.parseAsync(model, "/");

            glft.scene.userData.type = "Furniture";
            glft.scene.userData.state = furnState;
            glft.scene.name = details.description;

            this.updateFurnitureAsync(furnState, glft.scene);

            this._view.add(glft.scene);

            this._furnitureNode?.changed.raise({ target: this._furnitureNode, type: "children" });

            return glft.scene;
        }
    }

    getFurnitureFromView(view: Object3D) {

        const result = view.userData.state as IFurnitureObject;

        result.position = toSimpleVector(view.position);
        result.rotation = toSimpleVector(rotationVecDeg(view.rotation));

        const floor = this._view.children.find(a => a.userData.type == "FLOOR");

        if (floor) {

            const floorState = (floor.userData.state as ISceneObject);

            result.floorPosition = toSimpleVector(floor.worldToLocal(view.getWorldPosition(new Vector3())));
            result.floorRotation = toSimpleVector(rotationVecDeg(view.rotation).sub(floorState.rotation));
            result.floorQuaternion = toSimpleQuaternion(quatDiff(view.quaternion, toQuaternion(floorState.quaternion)));
        }

        return result;
    }

    async notifyFurnitureUpdateAsync(view: Object3D) {

        const state = this.getFurnitureFromView(view);

        await designerConnector.updateFurnitureAsync(state);
    }

    async updateFurnitureAsync(value: IFurnitureObject, view?: Object3D) {

        if (!view)
            view = this.findFurnitureViewById(value.instanceId);

        view.position.set(value.position.x, value.position.y, value.position.z);

        view.setRotationFromEuler(eulerFromDegVector(value.rotation));

        view.updateMatrix();
    }

    static async fromStateAsync(state: IRoomInfo, onProgress: IProgressHandler = null) {
        const result = new RoomScene();
        await result.initFromStateAsync(state, onProgress ?? services.progress);
        return result;
    }

    get view() {
        return this._view;
    }


    node() {
        return getOrBuildNode(this, {
            type: ["RoomScene"],
            components: [{ value: () => this._view }],
            displayName: "room",
            children: [
                {
                    displayName: "layout",
                    value: () => this._view.children.filter(a => a.userData.type != "Furniture"),
                    children: [
                        {
                            displayName: "walls",
                            value: () => this._view.children.filter(a => a.userData.type == "WALL_FACE")
                        },
                        {
                            value: () => this._view.children.find(a => a.userData.type == "FLOOR")
                        },
                        {
                            value: () => this._view.children.find(a => a.userData.type == "CEILING")
                        },
                        {
                            value: () => this._view.children.find(a => a.userData.type == "GLOBAL_MESH")
                        },
                    ]
                },
                {
                    displayName: "furnitures",
                    value: () => this._view.children.filter(a => a.userData.type == "Furniture"),
                    ref: n => this._furnitureNode = n as any,
                    children: (value: Object3D[]) => value.map(f => ({
                        type: ["Furniture", "3DObject"],
                        value: f
                    }))
                },
            ]
        });
    }
    
}