import { Object3D } from "three";
import { nodeManager } from "../NodeManager";
import { INodeHandler } from "../abstraction/INodeHandler";
import { Node } from "../Node";
import { SceneView } from "../../view/SceneView";
import { buildProps } from "../../helpers/EditorPropertiesBuilder";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";
import { withTemplate } from "@eusoft/webapp-jsx/Helpers";

export class GroupHandler implements INodeHandler<Object3D[]> {

    canHandle(node: Node<Object3D[]>): boolean {
        return node.type.includes("Group") && node.value != null && Array.isArray(node.value);
    }

    icon(node: Node<Object3D[]>) {
        return withTemplate(<MaterialIcon style="blue" name="folder" />);
    }

    properties(node: Node<Object3D[]>) {

        return buildProps(node, bld => bld
            .boolean("visible", prop => prop
                .value(n => n.value.every(a=> a.visible),
                    (v, n) => {
                        n.value.forEach(a => a.visible = v);
                        SceneView.active.requestRender();
                    })));
             
    } 

    readonly priority = 1;
}


nodeManager.register(new GroupHandler());