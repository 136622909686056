import { Object3D } from "three";
import { nodeManager } from "../NodeManager";
import { INodeHandler } from "../abstraction/INodeHandler";
import { Node } from "../Node";
import { SceneView } from "../../view/SceneView";
import { buildProps } from "../../helpers/EditorPropertiesBuilder";
import { getTypeName, toKebabCase } from "@eusoft/webapp-core";
import { withTemplate } from "@eusoft/webapp-jsx/Helpers";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";

export class Object3DHandler implements INodeHandler<Object3D> {

    canHandle(node: Node<Object3D>): boolean {
        return node.value != null &&
            (node.type.includes("3DObject") ||
                node.type.includes("_Object3D") ||
                node.type.includes("Object3D"));
    }

    icon(node: Node<Object3D>) {
        return withTemplate(<MaterialIcon style="green" name= "deployed_code" />);
    }


    displayName(node: Node<Object3D>) {
        return !node.value.name || node.value.name == "" ? toKebabCase(getTypeName(node.value)) : node.value.name;
    }

    properties(node: Node<Object3D>) {

        return buildProps(node, bld => bld
            .boolean("visible", prop => prop
                .value(n => n.value.visible,
                    (v, n) => {
                        n.value.visible = v;
                        SceneView.active.requestRender();
                    })));
             
    } 

    readonly priority = 0;

}


nodeManager.register(new Object3DHandler());