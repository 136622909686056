import { Camera, Color, Mesh, Object3D, Plane, PlaneGeometry, Raycaster, Scene,Vector2,WebGLRenderer } from "three";
import { BaseSceneTool } from "./BaseSceneTool";
import { getFurniture, objectsOfType } from "../Utils";
import { OutlineMaterial } from "../materials/OutlineMaterial";
import { SceneView } from "../SceneView";
import { AppEvent } from "../../helpers/AppEvent";


export const SELECTION_TOOL = "Selection";

export class SelectionTool extends BaseSceneTool {

    protected _overObject: Object3D;
    protected _sectedObjects: Object3D[];
    protected _outline: OutlineMaterial;
    private _suspend: number = 0;

    constructor() {
        super();
        this._sectedObjects = [];
        this._outline = new OutlineMaterial();


        this.selectionColor = new Color(0xFFFF00);
        this.overColor = new Color(0x0000FF);

    }

    isSelected(mesh: Object3D) {

        return this._sectedObjects.includes(mesh);
    }

    clearSelection() {
        this._sectedObjects.forEach(a => this.setSelected(a, false));
        this._sectedObjects = [];
        this.onChanged();
    }

    addSelection(obj: Object3D) {

        if (this.isSelected(obj))
            return;
        this._sectedObjects.push(obj);
        this.setSelected(obj, true);
        this.onChanged();
    }

    setSelection(...objs: Object3D[]) {

        this._suspend++;
        try {
            this.clearSelection();
            objs.forEach(a => this.addSelection(a));
        }
        finally {
            this._suspend--;
        }

        this.onChanged();
    }

    protected onChanged() {

        if (this._suspend > 0)
            return;
        this.changed.raise(this._sectedObjects);
        this._sceneView.requestRender();
    }

    protected setSelected(obj: Object3D, isSelected: boolean) {

    }

    override attach(view: SceneView) {
        super.attach(view)

        this._sceneView.container.addEventListener("pointermove", ev => this.onPointerMove(ev))

        this._sceneView.container.addEventListener("pointerdown", ev => this.onPointerDown(ev))
    }

    protected onPointerDown(ev: PointerEvent) {

        if (ev.button != 0)
            return;

        if (this._sectedObjects.length == 1 && this._overObject == this._sectedObjects[0])
            return;

        if (this._overObject)
            this.setSelection(this._overObject);
        else
            this.clearSelection();

        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();
    }

    protected onPointerMove(ev: PointerEvent) {

        if (!this.isActive || !this._sceneView.room)
            return;

        const raycaster = this._sceneView.raycast(ev);

        const intersects = raycaster.intersectObjects(this._sceneView.room.view.children);

        const newOver = intersects.map(a => getFurniture(a.object)).find(a => a != null);

        if (newOver != this._overObject) {
            this._overObject = newOver;
            this._sceneView.requestRender();
        }

    }

    update() {

    }

    onPostRender?(renderer: WebGLRenderer, camera: Camera): void {

        if (this._sectedObjects.length == 0 && !this._overObject)
            return;

        const checkList = [...this._sectedObjects];

        if (this._overObject && !checkList.includes(this._overObject))
            checkList.push(this._overObject);

        for (const obj of checkList) {

            for (const mesh of objectsOfType(Mesh, obj)) {
                mesh.userData.oldMaterial = mesh.material;
                mesh.material = this._outline;
            }

            if (obj == this._overObject && !this.isSelected(this._overObject))
                this._outline.uniforms["outlineColor"] = { value: this.overColor.toArray() }
            else
                this._outline.uniforms["outlineColor"] = { value: this.selectionColor.toArray() }

            renderer.render(obj, camera);

            for (const mesh of objectsOfType(Mesh, obj)) 
                mesh.material = mesh.material = mesh.userData.oldMaterial;
        }
    }

    get selectedObjects() {
        return this._sectedObjects;
    }

    selectionColor: Color;

    overColor: Color;

    readonly changed = new AppEvent<Object3D[]>();

    readonly name = SELECTION_TOOL;
}    