import { MainPage } from "./pages/MainPage";
import { App } from "@eusoft/webapp-framework/App";
import router from "@eusoft/webapp-framework/services/Router";
import localTable from "@eusoft/webapp-framework/services/LocalTable";
import "./nodes/handlers";
import "./App.scss";

export class RmApp extends App {

    protected override async onStarted() {

        localTable.language = "IT";

        router.addPage(MainPage)

        router.startAsync();
    }
}