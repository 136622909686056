import {  forModel } from "@eusoft/webapp-jsx";
import { ProgressView } from "@eusoft/webapp-ui/components/ProgressView";
import Content from "@eusoft/webapp-ui/components/Content";
import { IContentInfo } from "@eusoft/webapp-ui/abstraction/IContent";
import { services } from "../services/Services";
import { PanelHost } from "../components/PanelHost";
import { OutlinePanel } from "../panels/OutlinePanel";
import { ScenePanel } from "../panels/ScenePanel";
import "./MainPage.scss";
import { CloudPanel } from "../panels/CloudPanel";
import { PropertiesPanel } from "../panels/PropertiesPanel";
import { ILayout } from "../components/LayoutManager";


const defaultLayout: ILayout = {
    type: "split-v",
    sizes: [20, "px"],
    content: [
        {
            type: "split-h",
            sizes: [50, '%'],
            content: [
                [OutlinePanel, CloudPanel],
                PropertiesPanel
            ]
        },
        ScenePanel
    ]
}

export class MainPage extends Content {

    constructor() {

        super();

        this.init(MainPage, {
            title: "Room Designer",
            style: [],
            body: forModel(this, m => <>
                <div className="operation" visible={m.progressMessage != null}>
                    <ProgressView style="small" ref={m.progress} content=" "/> 
                    <div className="message">{m.progressMessage}</div>
                </div>

                <div className="main">
                    <div className="side-bar">
                        <PanelHost style={["first", "scroll"]}>
                            <OutlinePanel />
                            <CloudPanel />
                        </PanelHost>
                        <PanelHost style={["second", "scroll"]}>
                            <PropertiesPanel />
                        </PanelHost>
                    </div>
                    <PanelHost>
                        <ScenePanel />
                    </PanelHost>
                </div>
           
            </>)
        });

        services.progress = (item, count, msg) => {
            this.progress.max = count;
            this.progress.value = item;
            this.progressMessage = msg;
            if (count == 0)
                return 0;
        }        
    }

    progress: ProgressView;

    progressMessage: string;


    static override info = {
        name: "main-page",
        route: "/",
        factory: () => new MainPage()
    } as IContentInfo;
}
