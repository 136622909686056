import { IEditorProperty } from "../abstraction/IEditorProperty";
import { Node } from "./Node";
import { INodeHandler } from "./abstraction/INodeHandler";
import { isNodeProvider } from "./abstraction/INodeProvider";



class NodeManager {

    protected _handlers: INodeHandler<unknown, Node>[] = [];

    getHandlers<TNode extends Node<TValue>, TValue>(node: TNode) {

        return this._handlers.filter(a => a.canHandle(node)).sort((a, b) => b.priority - a.priority);
    }

    getProperties<TValue>(node: Node<TValue>) {

        const handlers = this.getHandlers(node);

        const props : IEditorProperty[] = [];

        for (const handler of handlers) {

            if (!handler.properties)
                continue;

            const curProps = handler.properties(node);
            if (curProps)
                props.push(...curProps);
        }

        if (node.components) {
            for (const comp of node.components) {

                const compProps = this.getProperties(comp);
                props.push(...compProps);
            }

        }
   
        return props;

    }

    register<TNode extends Node<TValue>, TValue>(handler: INodeHandler<TValue, TNode>) {
        this._handlers.push(handler);
    }
}


export const nodeManager = new NodeManager();