import { Component } from "@eusoft/webapp-core/Component";
import { forModel } from "@eusoft/webapp-jsx/Helpers";

export class VrMain extends Component {

    constructor() {

        super();

        this.init(VrMain, {
            style: [],
            template: forModel(m => <>
               <div>hello</div>
            </>)
        });
     
    }
    
}
