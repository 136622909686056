import { Object3D, Plane, Vector3 } from "three";
import { BaseSceneTool } from "./BaseSceneTool";
import { setWorldPostion,  } from "../Utils";
import { SceneView } from "../SceneView";


export const MOVE_TOOL = "Move";

export class MoveTool extends BaseSceneTool {

    protected _activeObject: Object3D;
    protected _plane: Plane;
    protected _deltaPos: Vector3;
    private _isNotify: any;

    constructor() {
        super();
    }

    override attach(view: SceneView) {
        super.attach(view)

        this._sceneView.container.addEventListener("pointermove", ev => this.onPointerMove(ev))

        this._sceneView.container.addEventListener("pointerdown", ev => this.onPointerDown(ev))

        this._sceneView.container.addEventListener("pointerup", ev => this.onPointerUp(ev))

        this._sceneView.container.addEventListener("mousedown", ev => this.onPointerDown(ev))

    }

    protected onPointerDown(ev: MouseEvent|PointerEvent) {

        if (!this.isActive || this._sceneView.selection.selectedObjects.length == 0 || ev.button != 0)
            return;

        const sel = this._sceneView.selection.selectedObjects[0];
        const raycaster = this._sceneView.raycast(ev);

        const intersect = raycaster.intersectObject(sel)[0];
        if (!intersect)
            return;

        this._sceneView.activeTool = this;

        this._activeObject = sel;

        this._plane = new Plane(new Vector3(0, 1, 0), -intersect.point.y);

        const startInt = raycaster.ray.intersectPlane(this._plane, new Vector3());

        this._deltaPos = startInt.sub(this._activeObject.getWorldPosition(new Vector3()));

        if ((ev as PointerEvent).pointerId)
            (ev.currentTarget as HTMLElement).setPointerCapture((ev as PointerEvent).pointerId);

        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();
    }

    protected onPointerMove(ev: PointerEvent) {

        if (!this._activeObject)
            return;

        const raycaster = this._sceneView.raycast(ev);

        const newInt = new Vector3();

        if (raycaster.ray.intersectPlane(this._plane, newInt)) {

            let newPos = newInt.sub(this._deltaPos);

            setWorldPostion(this._activeObject, newPos);

            this._sceneView?.requestRender();

            this._sceneView.room.notifyFurnitureUpdateAsync(this._activeObject)
        }

        ev.preventDefault();
        ev.stopPropagation();
        ev.stopImmediatePropagation();
    }

    protected onPointerUp(ev: PointerEvent) {

        if (!this._activeObject)
            return;

        (ev.currentTarget as HTMLElement).releasePointerCapture(ev.pointerId);

        this._activeObject = null;

        this._sceneView.activeTool = undefined;
    }

    update() {

    }
    

    readonly name = MOVE_TOOL;
}    