import { Object3D } from "three";
import { nodeManager } from "../NodeManager";
import { INodeHandler } from "../abstraction/INodeHandler";
import { Node } from "../Node";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";
import { withTemplate } from "@eusoft/webapp-jsx/Helpers";

export class FurnitureHandler implements INodeHandler<Object3D> {

    canHandle(node: Node<Object3D>): boolean {
        return node.type.includes("Furniture") && node.value != null;
    }

    icon(node: Node<Object3D>) {
        return withTemplate(<MaterialIcon style="green" name="bed" />);
    }


    readonly priority = 2;
}


nodeManager.register(new FurnitureHandler());