import { Class, forModel } from "@eusoft/webapp-jsx";
import { IPanelOptions, Panel } from "../components/Panel";
import { IHierarchicalItemsSource, TreeNode, TreeView } from "../components/TreeView";
import { Node } from "../nodes/Node";
import { SceneView } from "../view/SceneView";
import { panelManager } from "../services/PanelManager";
import { ScenePanel } from "./ScenePanel";
import { Attach, MaterialIcon } from "@eusoft/webapp-ui";
import { Object3D } from "three";
import { getFurniture } from "../view/Utils";
import { Bind, registerComponent } from "@eusoft/webapp-core";
import { nodeManager } from "../nodes/NodeManager";
import { IEditorProperty } from "../abstraction/IEditorProperty";
import { findDomParent, getDomOffset } from "../helpers/Dom";
import "./OutlinePanel.scss";

export interface IOutlinePanelState {

}


function NodeView(options: { node: Node }) {

    const props = nodeManager.getProperties(options.node);

    const visible = props.find(a => a.label == "visible") as IEditorProperty<boolean>;

    const actions = [];

    if (visible)
        actions.push(forModel(visible, m =>
            <span on-pointerdown={(_, ev) => {
                m.value = !m.value;
                ev.stopPropagation();
                ev.stopImmediatePropagation();
            }}>
                <Class condition={m.value} name="active" />
                <MaterialIcon name="visibility" />
            </span>
        ));

    const fixActions = (el: HTMLElement) => {
        const node = el.parentElement;
        let isObserverd = false;

        const execute = () => {

            const treeView = findDomParent<HTMLDivElement>(node, a => a.classList.contains("tree-view"));
            if (!treeView)
                return;
            const container = findDomParent<HTMLDivElement>(treeView, a => a.classList.contains("container"));
            if (!container)
                return;
            const header = findDomParent<HTMLDivElement>(node, a => a.tagName == "HEADER");
            if (!header)
                return;

            const ofs = getDomOffset(header, treeView);

            node.style.left = (container.clientWidth - node.clientWidth - ofs.x - 7 + container.scrollLeft) + "px";
            node.style.right = "unset";

            if (!isObserverd) {
                var observer = new ResizeObserver(() => execute());
                observer.observe(header);

                container.addEventListener("scroll", () => execute());
                isObserverd = true;
            }
        };

        setTimeout(execute);

    }

    return <>
        {options.node.icon}
        <span>{options.node.displayName}</span>
        <div className="actions">
            <Attach load={el => fixActions(el)} />
            {actions.forEach(a => a)}
        </div>
    </>
}


export class OutlinePanel extends Panel<IOutlinePanelState> {
    constructor(options?: IPanelOptions) {

        super();

        this.init(OutlinePanel, {
            content: forModel(this, m => <>
                <TreeView ref={m.treeView} selectedNode={Bind.twoWays(m.selectedNode)} itemsSource={m.itemsSource} />
            </>),
            icon: <MaterialIcon name="account_tree" />,
            name: "outline",
            title: "Outline",
            ...options
        });
    }

    override initProps() {

        this.onChanged("selectedNode", v => {

            if (v?.value.value instanceof Object3D) {
                const forn = getFurniture(v?.value.value);
                if (forn)
                    SceneView.active.selection.setSelection(forn);
            }

        });
    }

    override getState(ctx): IOutlinePanelState {
        return undefined;
    }

    override setState(value: IOutlinePanelState, ctx) {

    }

    protected override async loadWorkAsync() {

        const scenePanel = await panelManager.waitPanelAsync(ScenePanel);

        if (!scenePanel.sceneView.isReady)
            await scenePanel.sceneView.ready.waitAsync();

        this.itemsSource = {

            root: scenePanel.sceneView.node(),

            getChildrenAsync: a => a.children(),

            createView: v => <NodeView node={v} />,

            attach: (v, host) => {

                host["__handler"] = v.changed.subscribe(async ev => {
                    if (ev.type == "children")
                        await host.refreshChildrenAsync();
                });
            },

            detach: (v, host) => {

                v.changed.unsubscribe(host["__handler"]);
            },

            isLeaf: a => a.isLeaf
        }


        scenePanel.sceneView.selection.changed.subscribe(async sel => {

            if (sel.length == 0)
                return;

            const obj = sel[0];

            const node = await this.treeView.findNodeAsync(a => a.value == obj, false);
            if (node)
                node.isSelected = true;
        });

        return true;
    }



    treeView: TreeView<Node>;

    selectedNode: TreeNode<Node>;

    itemsSource: IHierarchicalItemsSource<Node, TreeNode<Node>>;
}

registerComponent(OutlinePanel, "OutlinePanel");