import { IComponentOptions } from "@eusoft/webapp-core";
import { Component, registerComponent } from "@eusoft/webapp-core/Component";
import { TemplateMap } from "@eusoft/webapp-core/abstraction/ITemplate";
import { forModel } from "@eusoft/webapp-jsx/Helpers";
import {  LocalString, ViewNode } from "@eusoft/webapp-ui/Types";
import "./Panel.scss";
import { panelManager } from "../services/PanelManager";
import { IStateManager } from "../abstraction/IStateManager";


export const PanelTemplates: TemplateMap<Panel> = {

    "Default": forModel(m => <div className={m.className} visible={m.visible}>
        <header>{m.header}</header>
        <section className="content">
            {m.content }
        </section>
    </div>)
}


export interface IPanelOptions extends IComponentOptions {

    header?: ViewNode;

    content?: ViewNode;

    icon?: ViewNode;

    title?: LocalString;

}

export class Panel<TState extends object = object> extends Component<IPanelOptions> implements IStateManager<TState> { 

    protected _isLoaded: boolean;

    constructor(options?: IPanelOptions) {

        super();

        this.init(Panel, {
            template: PanelTemplates.Default,
            style: ["panel"],

            ...options
        });
    }

    getState(ctx): TState {
        return undefined;
    }

    setState(value: TState, ctx) {

    }

    override initProps() {

    }

    override mount(ctx) {
        super.mount(ctx);
        this.loadAsync();
    }

    async loadAsync() {

        if (!this._isLoaded) {
            panelManager.notifyLoad(this);
            this._isLoaded = await this.loadWorkAsync();
        }

        return this._isLoaded;
    }

    protected async loadWorkAsync() {
        return true;
    }

    close() {

    }

    icon: ViewNode;

    title: LocalString;

    header: ViewNode;

    content: ViewNode;
}


registerComponent(Panel, "Panel");