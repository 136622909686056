import { IRoomInfo } from "./Entities";

class SceneManager {

    async loadAsync(url: string) {

        this.current = await (await fetch(url)).json();
        return this.current;
    }

    current: IRoomInfo;
}

export const sceneManager = new SceneManager();