import { Class, forModel } from "@eusoft/webapp-jsx";
import { EditorPropertyType, IEditorProperty } from "../abstraction/IEditorProperty";
import { LocalString } from "@eusoft/webapp-ui/Types";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";


class PropertyBuilder<THost, TProp> {

    readonly _host: THost;

    constructor(host: THost) {
        this._host = host;
    }

    value(get: (host: THost) => TProp, set: (value: TProp, host: THost) => void) {

        Object.defineProperty(this.property, "value", {
            get: () => get(this._host),
            set: v => set(v, this._host),
            enumerable: true,
            configurable: true
        });

        return this;
    }

    set(value: Partial<IEditorProperty<TProp>>) {

        Object.assign(this.property, value);
        return this;
    }




    property = {} as IEditorProperty<TProp>;
}


class EditorPropertiesBuilder<TValue> {

    readonly _host: TValue;

    constructor(host: TValue) {
        this._host = host;
    }

    boolean(label: LocalString, build: (bld: PropertyBuilder<TValue, boolean>) => void) {
        const result = this.buildProperty("boolean", label, build);
        result.showLabel = false;
        if (!result.editor) {
            result.editor = forModel(result, m => <label on-click={() => m.value = !m.value}>
                <span className="checkbox" >
                    <Class name="checked" condition={m.value} />
                    <MaterialIcon name="check"/>
                </span>
                <span>{m.label}</span>
            </label>);
        }
        return this;
    }

    float(label: LocalString, build: (bld: PropertyBuilder<TValue, number>) => void) {
        const result = this.buildProperty("float", label, build);

        if (!result.editor) {
            result.editor = forModel(result, m => <div>
                <input type="number" step={m.step ?? 1} value={m.value} />
            </div>);
        }
        return this;
    }

    protected buildProperty<TProp>(type: EditorPropertyType, label: LocalString, build: (bld: PropertyBuilder<TValue, TProp>) => void) {

        const builder = new PropertyBuilder<TValue, TProp>(this._host);

        builder.property.type = type;
        builder.property.label = label;
        builder.property.showLabel = true;

        build(builder);

        this.properties.push(builder.property);

        return builder.property;
    }


    properties: IEditorProperty<unknown>[]=[];
}


export function buildProps<TValue>(value: TValue, build: (bld: EditorPropertiesBuilder<TValue>) => void) {
    const builder = new EditorPropertiesBuilder<TValue>(value);
    build(builder);
    return builder.properties;
}