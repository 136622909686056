import { Panel } from "../components/Panel";


interface ILoadHandler<T extends Panel = Panel> {

    type: Constructor<T>;

    call: (panel: T) => void;
}

class PanelManager {

    protected _panels = new Map<Function, Panel>();
    protected _loadHandlers: ILoadHandler[] = [];

    notifyLoad(panel: Panel) {

        this._panels.set(panel.constructor, panel);
        for (const handler of this._loadHandlers) {
            if (handler.type == panel.constructor)
                handler.call(panel);
        }
    }

    onLoad<T extends Panel>(ctr: Constructor<T>, hander: (panel: T) => void) {

        const curPanel = this._panels.get(ctr);
        if (curPanel)
            hander(curPanel as T);
        else {
            this._loadHandlers.push({
                type: ctr,
                call: hander
            });
        }
    }

    async waitPanelAsync<T extends Panel>(ctr: Constructor<T>) {

        return new Promise<T>(res => {
            this.onLoad(ctr, v => res(v));
        })
    }
}


export const panelManager = new PanelManager();