
import { Node } from "./Node";
import { INodeProvider, isNodeProvider } from "./abstraction/INodeProvider";

export const NODE = Symbol("node");

type NodeLayoutType<TValue = unknown> = INodeLayout<TValue> | Node<TValue> | INodeProvider | object;

export interface INodeLayout<TValue> {

    type?: string | string[];
    value?: TValue | { (): TValue };
    displayName?: string | { (): string };
    components?: NodeLayoutType[];
    ref?: (node: Node<TValue>) => void;
    children?: NodeLayoutType[] | { (value?: TValue): (NodeLayoutType[] | Promise<NodeLayoutType[]>) };
}

export function isNodeLayout<T>(obj: unknown): obj is INodeLayout<T> {
    return obj &&
        typeof (obj) == "object" &&
        ("value" in obj || "displayName" in obj || "children" in obj || "type" in obj)

}

export function getOrBuildNode<TValue>(provider: INodeProvider<TValue>, layout: INodeLayout<TValue>, parent?: Node): Node<TValue> {
    if (!provider[NODE])
        provider[NODE] = buildNode({ value: provider, ...layout }, parent);
    return provider[NODE];
}

export function buildNode<TValue>(layout: INodeLayout<TValue>, parent?: Node): Node<TValue> {

    const curNode = new Node<TValue>(parent);
    curNode.type = Array.isArray(layout.type) ? layout.type : (layout.type ? [layout.type] : []);

    if (typeof layout.value == "function") {
        Object.defineProperty(curNode, "value", {
            get: layout.value as () => unknown,
            enumerable: true
        });
    }
    else
        curNode.value = layout.value;

    if (typeof layout.displayName == "function") {
        Object.defineProperty(curNode, "displayName", {
            get: layout.displayName,
            enumerable: true
        });
    }
    else
        curNode.displayName = layout.displayName;

    const build = (child: NodeLayoutType<unknown>) => {

        if (isNodeProvider(child))
            return child.node();

        if (child instanceof Node)
            return child;

        if (isNodeLayout(child))
            return buildNode(child);

        return buildNode({ value: child });
    }

    if (layout.components) 
        curNode.components = layout.components.map(build).filter(a => a != null);
    

    if (!layout.children) {
        curNode.children = () => [];
        curNode.isLeaf = true;
    }
    else if (Array.isArray(layout.children)) {
        const children = layout.children.map(build).filter(a => a != null);
        children.forEach(a => a.parent = curNode);
        curNode.children = () => children;
    }
    else {
        curNode.children = async () => {
            const childrenLayout = (await (layout.children as Function)(curNode.value)) as NodeLayoutType<unknown>[];
            const children = childrenLayout.filter(a => a != null).map(build);;
            children.forEach(a => a.parent = curNode);
            return children
        }
    }

    curNode.update();

    if (layout.ref)
        layout.ref(curNode);

    return curNode;
}