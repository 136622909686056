import { ISceneTool } from "../abstraction/ISceneTool";
import { SceneView } from "../SceneView";

export abstract class BaseSceneTool implements ISceneTool {

    protected _sceneView: SceneView;
    protected _isActive = false;

    attach(view: SceneView) {
        this._sceneView = view;
        this.activate();
    }

    activate() {
        this._isActive = true;
    }


    deactivate() {
        this._isActive = true;
    }

    get isActive() {
        return this._isActive;
    }

    abstract readonly name: string;
}