import { nodeManager } from "../NodeManager";
import { INodeHandler } from "../abstraction/INodeHandler";
import { Node } from "../Node";
import { buildProps } from "../../helpers/EditorPropertiesBuilder";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";
import { withTemplate } from "@eusoft/webapp-jsx/Helpers";
import { Player } from "../../view/Player";


export class PlayerNodeHandler implements INodeHandler<Player> {

    canHandle(node: Node<Player>): boolean {
        return node.value instanceof Player;
    }

    icon(node: Node<Player>) {
        return withTemplate(<MaterialIcon style="blue" name="person" />);
    }


    properties(node: Node<Player>) {

        return buildProps(node, bld => bld
            .boolean("track-camera", prop => prop
                .value(() => node.value.attachToCamera,
                    v => node.value.attachToCamera = v))); 
    }

    readonly priority = 1;

}


nodeManager.register(new PlayerNodeHandler());