import { BufferGeometry, Group, Line, LineBasicMaterial, Vector3 } from "three";
import { SceneView } from "./SceneView";

export class SceneDebug {

    protected _view: Group;
    protected _lineMaterials: Record<string, LineBasicMaterial> = {};
    protected _sceneView: SceneView;

    constructor(sceneView: SceneView) {
        this._view = new Group();
        this._sceneView = sceneView;
        this._sceneView.scene.add(this._view);
    }

    clear() {
        this._view.clear();
    }

    protected getLineMaterial(color: string) {
        let result = this._lineMaterials[color];
        if (!result) {
            result = new LineBasicMaterial({ color });
            result.depthTest = false;
            result.depthWrite = false;
            this._lineMaterials[color] = result;
        }
        return result;
    }

    drawLine(start: Vector3, end: Vector3, color: string, id: string) {

        const mat = this.getLineMaterial(color);

        let curObj = this._view.children.find(a => a.name == id) as Line;

        if (!curObj) {
            curObj = new Line(new BufferGeometry(), mat);
            curObj.name = id;
            this._view.add(curObj);
        }

        curObj.geometry.setFromPoints([start, end]);
        curObj.material = mat;

        this._sceneView.requestRender();
    }

}