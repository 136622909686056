import { RoomScene } from "../view/RoomScene";
import { SceneView } from '../view/SceneView';
import { apiClient } from "./ApiClient";
import { appSettings } from "./AppSettings";
import { IOculusLogin, IOculusLoginResult } from "./Entities";
import { sceneManager } from "./SceneManager";
import { services } from "./Services";
import { userSession } from "./UserSession";


export const actions = {


    async loadSceneFromUriAsync(uri: string) {

        services.progress(0, 1, "Download scene " + uri);

        const sceneState = await sceneManager.loadAsync(uri);

        const room = await RoomScene.fromStateAsync(sceneState);

        SceneView.active.loadRoom(room);

        room.center();
    },

    async loginAsync() {

        return new Promise<IOculusLoginResult>(res => {
            window.open(
                "https://auth.oculus.com/sso/?redirect_uri=" + appSettings.baseUrl + "/oculus/oauth&organization_id=263983600024114",
                "login",
                "popup=true,resizable=0,width=500,height=600"
            );

            const channel = new BroadcastChannel("login");

            channel.addEventListener("message", async ev => {

                channel.close();

                const json = atob((ev.data as string).substring(1));

                console.log(JSON.parse(json));

                const result = await apiClient.oculusLogin(JSON.parse(json) as IOculusLogin);

                if (result?.accessToken)
                    await userSession.openAsync(result);

                res(result);

            });


        });
    }
}