import { randomUUID } from "../view/Utils";
import { Guid } from "./Entities";

interface IUser {
    userId: string;
    username: string;
    accessToken: string;
}

class UserSession {

    protected _user: IUser;

    constructor() {

        var json = localStorage.getItem("session");
        if (json)
            this._user = JSON.parse(json);

        this.deviceId = localStorage.getItem("deviceId");
        if (!this.deviceId) {
            this.deviceId = randomUUID();
            localStorage.setItem("deviceId", this.deviceId);
        }
    }

    openAsync(user: IUser) {
        this._user = user;
        localStorage.setItem("session", JSON.stringify(user));
        return Promise.resolve();
    }

    closeAsync() {
        this._user = null;
        localStorage.removeItem("session");
        return Promise.resolve();
    }

    get user() {
        return this._user;
    }

    readonly deviceId: Guid;

}

export const userSession = new UserSession();