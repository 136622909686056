import { BaseApiClient } from "@eusoft/webapp-framework";
import { IFurnitureDetailsView, IFurnitureIndex, IFurnitureListFilter, IFurnitureListView, IListResult, IOculusLogin, IOculusLoginResult } from "./Entities";
import { appSettings } from "./AppSettings";


class ApiClient extends BaseApiClient {

    protected _defaultCatalog = "ikea";

    constructor() {
        super(appSettings.baseUrl + "/api/latest/");
    }

    async oculusLogin(data: IOculusLogin) {
        return this.requestJsonAsync<IOculusLoginResult>("oculus/login", "GET", null, data);
    }

    async catalogIndexAsync() {
        return this.requestJsonAsync<IFurnitureIndex>(`${this._defaultCatalog}/index`, "GET");
    }

    async catalogListAsync(filter: IFurnitureListFilter) {
        return this.requestJsonAsync<IListResult<IFurnitureListView>>(`${this._defaultCatalog}`, "GET", null, filter);
    }

    async catagalogGetDetailsAsync(id: string) {
        return this.requestJsonAsync<IFurnitureDetailsView>(`${this._defaultCatalog}/${id}`, "GET")
    }

    async catalogOpenResourceAsync(resId: string) {

        const cache = await caches.open("resources");

        const fullUrl = `${this.endpoint}${this._defaultCatalog}/res/${resId}`

        let response = await cache.match(fullUrl);

        if (!response) {
            response = await this.requestAsync(`${this._defaultCatalog}/res/${resId}`)
            cache.put(fullUrl, response.clone());

        }
        return await response.arrayBuffer();
    }
}

export const apiClient = new ApiClient();