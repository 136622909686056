import { BIND_MODES, BindMode, IComponentOptions, TemplateBuilder, bind, cleanProxy, propOf } from "@eusoft/webapp-core";
import { Component } from "@eusoft/webapp-core/Component";
import { TemplateMap } from "@eusoft/webapp-core/abstraction/ITemplate";
import { CatalogTemplate } from "@eusoft/webapp-core/abstraction/ITemplateProvider";
import { Class } from "@eusoft/webapp-jsx";
import { forModel } from "@eusoft/webapp-jsx/Helpers";
import { MaterialIcon } from "@eusoft/webapp-ui";
import { ViewNode } from "@eusoft/webapp-ui/Types";
import "./TreeView.scss";


export const TreeViewTemplates: TemplateMap<TreeView<unknown>> = {

    "Default": forModel(m => <div visible={m.visible} className={m.className}>
        {m.root}
    </div>)
}


export const TreeNodeTemplates: TemplateMap<TreeNode<unknown>> = {

    "Default": forModel(m => <div className="tree-node">
        <Class condition={m.isSelected} name="selected" />
        <Class condition={m.isExpanded} name="expanded" />
        <Class condition={(!m.children || m.children.length == 0) && m.loadState == "loaded"} name="no-children" />
        <header >
            <button on-click={()=>m.toggle()} className="expand-collapse"><MaterialIcon name="chevron_right"/></button>
            <div className="view" on-pointerdown={() => m.isSelected = true}>{m.header}</div>
        </header>
        <ul className="chidren">
            {m.children?.forEach(n => <li>{n}</li>)}
        </ul>
    </div>)
}

export class TreeNode<TValue> {

    protected _parent: TreeNode<TValue>;
    protected _treeView: TreeView<TValue>;

    constructor(treeView: TreeView<TValue>, parent: TreeNode<TValue>) {

        this._parent = parent;

        this._treeView = treeView;

        this.template = TreeNodeTemplates["Default"];

        this.isExpanded = false;

        this.isSelected = false;

        this.loadState = "not-loaded";

        this.children = [];

        propOf(this, "isExpanded").subscribe(v => {
            if (v && this.loadState == "not-loaded")
                this.refreshChildrenAsync();
        });

        propOf(this, "isSelected").subscribe(v => {
            if (v)
                this._treeView.selectedNode = this;
            else {
                if (this._treeView.selectedNode == this)
                    this._treeView.selectedNode = null
            }
        });

        propOf(this, "value").subscribe(v => {

            if (this._treeView.itemsSource.isLeaf(v)) {
                this.children = [];
                this.loadState = "loaded";
            }
        });
    }

    remove() {

        if (this._treeView.selectedNode == this)
            this._treeView.selectedNode = null;

        this.clearChildren();

        if (this._treeView.itemsSource?.detach)
            this._treeView.itemsSource.detach(this.value, this);

        this._parent = null;
        this._treeView = null;
    }

    clearChildren() {

        if (!this.children)
            return;

        for (const child of this.children)
            child.remove();

        this.children = [];
    }

    async refreshChildrenAsync(force = false) {

        const oldSelection = this._treeView.selectedValue;

        this.clearChildren();

        if (!this.isExpanded && !force) {
            this.loadState = "not-loaded";
            return;
        }

        this.loadState = "loading";

        const childValues = await this._treeView.itemsSource.getChildrenAsync(this.value);

        if (childValues)
            this.children = Array.from(childValues).map(a => {

                const newNode = this._treeView.createNode(a, this);
                if (oldSelection && a == oldSelection)
                    newNode.isSelected = true;
                return newNode;
            });

        this.loadState = "loaded";
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

    get parent() {
        return this._parent;
    }

    value: TValue;

    template: CatalogTemplate<this>;

    loadState: "not-loaded" | "loading" | "loaded";

    isExpanded: boolean;

    isSelected: boolean;

    header: ViewNode;

    children: TreeNode<TValue>[];
}

export interface IHierarchicalItemsSource<TItem, THost> {

    createView?(value: TItem, host?: THost): ViewNode;

    getChildrenAsync(value: TItem): Promise<Iterable<TItem>> | Iterable<TItem>;

    isLeaf?(value: TItem): boolean;

    attach?(value: TItem, host?: THost): void;

    detach?(value: TItem, host?: THost): void;

    readonly root: TItem;
}


export interface ITreeViewOptions<TValue> extends IComponentOptions {

    itemsSource: IHierarchicalItemsSource<TValue, TreeNode<TValue>>;

    root?: TreeNode<TValue>;

    selectedNode?: TreeNode<TValue>;
}


export class TreeView<TValue> extends Component<ITreeViewOptions<TValue>> { 

    constructor(options?: ITreeViewOptions<TValue>) {

        super();

        this.init(TreeView, {
            template: TreeViewTemplates.Default,
            style: ["tree-view"],
            ...options
        });
    }

    override initProps() {

        this.onChanged("selectedNode", (newNode, oldNode) => {

            if (oldNode)
                oldNode.isSelected = false;

            if (newNode) 
                newNode.isSelected = true;

            this.selectedValue = newNode?.value;
        });

        this.onChanged("selectedValue", async (newVal, oldVal) => {

            if (newVal) {
                const selNode = await this.findNodeAsync(a => a == newVal, false);
                if (selNode)
                    this.selectedNode = selNode;
            }
        });

        this.onChanged("itemsSource", value => {

            if (value)
                this.root = this.createNode(value.root);
        });

        this.onChanged("root", (v, o) => {

            o?.clearChildren();
        });
    }

    createNode(value: TValue, parent?: TreeNode<TValue>) {

        const node = new TreeNode<TValue>(this, parent);

        node.value = value;
        node.header = this.itemsSource.createView(value);

        if (this.itemsSource.attach)
            this.itemsSource.attach(value, node);
        return node;
    }

    async findNodeAsync(selector: (value: TValue) => boolean, autoExpand: boolean): Promise<TreeNode<TValue>> {

        const processNodeAsync = async (node: TreeNode<TValue>) => {

            if (selector(node.value))
                return node;

            if (autoExpand && !node.isExpanded) {

                if (node.loadState == "not-loaded")
                    await node.refreshChildrenAsync(true);
            }

            if (node.children != null) {
                for (const child of node.children) {
                    const result = await processNodeAsync(child);
                    if (result)
                        return result;
                }
            }

        }

        return await processNodeAsync(this.root);
    }

    itemsSource: IHierarchicalItemsSource<TValue, TreeNode<TValue>>;

    root: TreeNode<TValue>;

    selectedNode: TreeNode<TValue>;

    selectedValue: TValue;

    static [BIND_MODES] = {

    } as Record<keyof ITreeViewOptions<unknown>, BindMode>
}