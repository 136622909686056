import { Class, forModel } from "@eusoft/webapp-jsx";
import { IPanelOptions, Panel } from "../components/Panel";
import { MaterialIcon } from "@eusoft/webapp-ui/components/Icon";
import { panelManager } from "../services/PanelManager";
import { OutlinePanel } from "./OutlinePanel";
import { Node } from "../nodes/Node";
import { nodeManager } from "../nodes/NodeManager";
import { IEditorProperty } from "../abstraction/IEditorProperty";
import "./PropertiesPanel.scss";
import { registerComponent } from "@eusoft/webapp-core";

export class PropertiesPanel extends Panel {
    constructor(options?: IPanelOptions) {

        super();

        this.init(PropertiesPanel, {
            content: forModel(this, m => <fieldset>
                {m.properties.forEach(p => <div>
                    <Class name={p.type}/>
                    {p.showLabel && <label>{p.label}</label>}
                    <div className="editor">{p.editor}</div>
                </div>) }
            </fieldset>),
            icon: <MaterialIcon name="list" />,
            title: "Properties",
            name: "properties",
            ...options
        });
    }

    loadProperties(node: Node) {

        this.properties = [];

        if (!node) {

            return;
        }

        this.properties = nodeManager.getProperties(node);
 
    }

    protected override async loadWorkAsync() {

        const outline = await panelManager.waitPanelAsync(OutlinePanel);

        outline.treeView.onChanged("selectedValue", v => this.loadProperties(v));

        return true;
    }

    properties: IEditorProperty<unknown>[] = [];
}

registerComponent(PropertiesPanel, "PropertiesPanel");