import { cache } from "three/examples/jsm/nodes/Nodes.js";



type AppEventHandler<TArgs> = (args: TArgs) => void|Promise<void>;

export class AppEvent<TArgs> {

    protected _handlers: AppEventHandler<TArgs>[];

    subscribe<T extends AppEventHandler<TArgs>>(handler: T): T {
        if (!this._handlers)
            this._handlers = [];
        if (!this._handlers.includes(handler))
            this._handlers.push(handler)
        return handler;
    }

    unsubscribe<T extends AppEventHandler<TArgs>>(handler: T) {
        if (!this._handlers)
            return;
        const index = this._handlers.indexOf(handler);
        if (index != -1)
            this._handlers.splice(index, 1);
    }

    async raise(args?: TArgs) {
        if (!this._handlers)
            return;

        for (const handler of this._handlers) {
            try {
               await handler(args);
            }
            catch (ex) {
                console.error(ex);
            }
        }
    }

    waitAsync() {

        return new Promise<TArgs>(res => {

            const handler = this.subscribe(arg => {
                res(arg);
                this.unsubscribe(handler);
            });

        });
    }
}